
html {
    font-size: 16px;
  }

body {
  // font-family: 'Nunito', 'Segoe UI', Roboto,
  //   Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  //   'Segoe UI Symbol';
  // 
  font-family: ms-sans-serif, sans-serif;
  line-height: 1.6;
  color: #333;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.header-logo {
  font-family: 'Sigmar One', cursive;
  font-size: 3rem;
  color: #3498db;
}

.header-menu {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.header-menu li {
  margin-right: 1.5em;
  padding: 1em 0;
}

.header-menu a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.header-menu a:hover {
  color: #3498db;
}

.main-title {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.main-title span {
  color: #3498db;
}

.main-content {
  font-size: 1.25rem;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

pre {
  font-size: 1rem;
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 3px;
  overflow-x: auto;
}

.FullWidthButton  {
  width: 100%; 
  display: block;
  margin: 0;
  margin-bottom: 7px;
}

.SelectedNFTsStrap {
  border-top: 1px solid #00000021;
  padding-top: 0 !important;
  box-shadow: 0px 0 7px 0px #00000021 !important;
  display: flex;
  align-items: center; 
}

.separator {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

header {
  top: auto !important;
  bottom: 0 !important;
  z-index: 10;

  img {
    height: 17px;
  }

  li {
    justify-content: flex-start !important;
  }

  .small-icon {
    width: 17px;
    height: 17px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 12px !important;
  }
}
.full-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 220px;
}

.window {
  max-width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}

.image-preview {
  height: 225px;
  display: flex;
  justify-content: center;
}

.oopsie-preview-window {
  width: 250px;
}

.main-nft-content-window {
  max-width: calc(100vw - 290px);
  height: 600px;
  max-height: calc(100vh - 250px);
  width: 560px;
}

.horizontal-preview-window {
position: fixed !important;
    left: 10px;
    right: 10px;
    bottom: 60px;
    height: 160px;
}

.selected-nfts-view {
  display: flex;
  flex-direction: row;
}

.monitor-preview {

  div[data-testid] {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  img,
  canvas {
    height: 100%;
    max-width: 100%;
  }
}

.my-nfts-groupbox {
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  min-height: 95%;

  ul {
    width: 100%;
    justify-self: stretch;
  }
}

.centered-alert {
  position: absolute !important;
  top: calc(50vh - 100px);
  left: calc(50vw - 150px);
  width: 300px !important;
  height: 200px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .small-icon {
    margin-right: 15px;
    width: 25px;
    height: 25px;
    background-size: contain;
    background-position: center;
  }
}

.full-text-wrapper {
  width: 100%;
  margin: 20px auto;
}

.centered-text {
  text-align: center;
}

.monitor-preview > div > div:first-child {
  height: 195px !important;
}

.nft-listing-treeview {
  button {
    span {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    span.selected-list-item {
      color: green;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.dropdown-menu {
  position: absolute !important;
  top: 33px;
  z-index: 10;
  width: 120px !important;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:space-around;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
}

.main-scroll-view {
  background: white;
}

.select-all-button {
  min-width: 70px;
}

.directory-icon {
  padding-right: 10px;
}

.selected-state {
  width: 15px;
  height: 15px;
  color: rgb(93, 93, 93) !important;
  display: block;
  position: relative;
  border: 1px solid silver;
  box-shadow: 1px 1px 0 rgb(122, 122, 122);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  margin-left: 20px;
  padding-bottom: 2.1px;

  &::before {
    content: "";
    position: absolute;
    right: -16px;
    top: 5px;
    width: 11px;
    height: 1.5px;
    display: block;
    border-bottom: 2px #9d9d9d;
    border-bottom-style: dashed;
  }
}

.accordion-box {
  &.selected {
    div {
      color: #09a309;
    }
  }

  img {
    padding-right: 10px;
    width: 40px;
    height: 30px;
    margin-right: 2px;
    margin-bottom: 3px;
    margin-left: 20px;
  }
}

.main-nft-content-window {
  .chakra-accordion {
    width: 100% !important;
    cursor: pointer;
  }

  .chakra-accordion__button {
    background: transparent !important;
    border: none !important;
    cursor: pointer;
  }

  .my-nfts-groupbox {
    box-shadow: none;
    margin-top: 0;
    
    legend {
      background: transparent;
    }
  }
}

.accordion-button::before {
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-image: url('/images/directory_closed-2.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.accordion-button[aria-expanded="true"]::before {
  background-image: url('/images/directory_open_cool-4.png');
}

/* Extra small devices (phones, up to 600px) */
@media only screen and (max-width: 600px) {

  

}


/* Small devices (portrait tablets and large phones, 601px to 768px) */
@media only screen and (max-width: 768px) {
  .full-wrapper {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }

  .oopsie-preview-window,
  .main-nft-content-window {
    max-width: 100% !important;
    width: calc(100vw - 30px) !important;
    margin-top: 15px !important;
    margin-bottom: 30px !important;

    .footer {
      font-size: 12px;
    }
  }

  .horizontal-preview-window {
    position: relative !important;
    width: calc(100vw - 30px);
    left: auto;
    right: auto;
    bottom: auto;
  }

  .centered-modal {
    margin-top: calc(50vh - 200px);
  }
}


/* Medium devices (landscape tablets, 769px to 992px) */
@media only screen and (min-width: 769px) and (max-width: 992px) {

}


/* Large devices (laptops/desktops, 993px to 1200px) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {

}


/* Extra large devices (large laptops and desktops, 1201px and up) */
@media only screen and (min-width: 1201px) {

}