// components/TerminalLoadingScreen.scss
.terminal-wrapper {
    color: white;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    pre {
        background: transparent !important;
        padding: 0;
        margin: 0;
    }

    pre span {
        display: inline;
        position: static;
        transform: none;
        letter-spacing: normal;
        margin: 0;
        padding: 0;
      }
}

.terminal-logo {
    color: #00ff00;
    display: block;
  }

.terminal-loading-screen {

    font-family: 'Inconsolata', 'Courier New', monospace;
    font-weight: 600;
    padding: 1rem;
    overflow-wrap: break-word;
    white-space: pre-wrap;
      
  }
  
  .green {
    color: #00ff00;
    padding-left: 20px !important;
  }
  
  .cyan {
    color: #00ffff;
  }
  
  .highlight {
    color: #ff00ff;
  }
    
  .yellow {
    color: #fffd88;
  }
  
  @keyframes blink {
    0%, 49% {
      background-color: #dddddd;
    }
    50%, 100% {
      background-color: transparent;
    }
  }
  
  .cursor {
    display: inline-block;
    width: 8px;
    height: 16px;
    background-color: #dddddd;
    position: relative;
    z-index: 1;
    animation: blink 1s linear infinite;
  }
  
